<template>
  <div class="home">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row" v-if="noticeCount>0">
        <div class="col-12 pb-0">
          <h5 class="mb-0 mt-2 text-success">
            <marquee scrollamount="5">
              <router-link class="link-success" :to="{name:'Notice'}" v-for="(activeNotice,key) in notices" :key="key"><span class="bf-1">{{activeNotice.bn_title}}</span> | <span style="font-family: Rockwell">{{activeNotice.en_title}}</span></router-link>
            </marquee>
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 ps-0 pe-0">
          <slider v-on:childToParent="onSliderUpdate"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Slider from "../components/home/Slider";
  import Preloader from "../components/Preloader";
export default {
  name: 'Home',
  data(){
    return{
      activeNoticeCount:null,
      activeNotices:null,
      loaded:false
    }
  },
  beforeMount(){

  },
  components: {
    'slider':Slider,Preloader
  },
  created(){

  },
  mounted(){

  },
  updated() {

  },
  computed:{
    notices(){return this.$store.getters.getNotices;},
    noticeCount(){return this.$store.getters.getNoticeCount;}
  },
  methods:{
    onSliderUpdate (value) {
      this.loaded = value;  // value is true
    }
  }
}
</script>

<style scoped>
  a{
    text-decoration: none;
  }
</style>
