<template>
  <div id="slider">
    <div class="slider" v-if="slides !== null && slides.length > 0">
      <carousel :autoplay="true" :nav="false" :dots="true" :items="3" :loop="true" :smartSpeed="700" :responsive="responsiveControls">
        <template slot="prev"><span class="fa fa-arrow-left prev"></span></template>
        <div class="m-1" v-for="(slide,key) in slides" :key="key">
          <img :src="slide.image" alt="main-banner1" class="img-thumbnail" />
          <div class="p-2 pb-0 rounded bg-success text-white text-start description">
            <h5 class="">{{slide.title}}</h5>
            <p class="pb-1">{{slide.description}}</p>
          </div>
        </div>
        <template slot="next"><span class="fa fa-arrow-right next"></span></template>
      </carousel>
    </div>
  </div>
</template>

<script>
  import carousel from 'vue-owl-carousel';
  export default {
    name: "Slider",
    data(){
      return {
        responsiveControls:{
          0:{items:1,nav:false},
          600:{items:2,nav:false},
          1024:{items:3,nav:false},
        },
        slides:null,
      }
    },
    components:{
      carousel,
    },
    beforeCreate() {
      this.$http.get('slider-images').then(response => {
        this.slides = response.body;
      });
    },
    created(){

    },
    updated() {
      this.emitToParent();
    },
    methods:{
      emitToParent () {
        this.$emit('childToParent', true)
      }
    }
  }
</script>

<style scoped>
  .slider{
    position: relative;
  }

  .description{
    font-family: Rockwell;
  }

  .prev,.next{
    display: inline-block;
    background-color: rgba(0,0,0,0.2);
    color: white;
    padding: 10px;
    z-index: 9999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .prev{
    left: 0;
    border-radius: 0px 8px 8px 0px;
  }
  .next{
    right: 0;
    border-radius: 8px 0px 0px 8px;
  }

  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.8);
  }
</style>